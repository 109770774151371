<template>
  <v-data-table
    :headers="$vuetify.breakpoint.xs ? headersXs : headers"
    :items="items"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    class="elevation-1 align-self-start col-12 pa-0"
    item-key="id"
  >
    <template v-slot:top>
      <div v-if="$vuetify.breakpoint.xs" flat color="white">
        <!-- Vista Mobile -->
        <v-toolbar flat color="white" dense>
          <v-toolbar-title>Clientes</v-toolbar-title>
        </v-toolbar>

        <v-select
          ref="zonas"
          v-model="idZona"
          :items="zonas"
          item-text="detalle"
          item-value="id"
          label="Todas las Zonas"
          placeholder="Todas las Zonas"
          dense
          single-line
          hide-details
          return-object
          clearable
          class="mx-4 mb-4"
        ></v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          dense
          single-line
          hide-details
          class="mx-4 mb-4"
        ></v-text-field>
      </div>
      <v-toolbar v-else flat color="white">
        <!-- Vista Desktop -->
        <v-toolbar-title>Clientes</v-toolbar-title>
        
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-select
          ref="zonas"
          v-model="idZona"
          :items="zonas"
          item-text="detalle"
          item-value="id"
          label="Todas las Zonas"
          placeholder="Todas las Zonas"
          single-line
          hide-details
          return-object
          clearable
          :style="!$vuetify.breakpoint.xs ? 'width: 10%;' : ''"
        ></v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          class="ml-4"
          :style="!$vuetify.breakpoint.xs ? 'width: 10%;' : ''"
        ></v-text-field>

        <v-dialog v-model="dialogContacto" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ item.RazonSocial }}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 300px;">
              <v-row no-gutters>
                <v-col>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Nro. de Cuenta</v-list-item-subtitle>
                      <v-list-item-title>{{ item.NroCuenta }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Documento</v-list-item-subtitle>
                      <v-list-item-title>{{ item.TipoDoc }} {{ item.NroDoc }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Categoría IVA</v-list-item-subtitle>
                      <v-list-item-title>{{ item.categoriaIva }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-list dense two-line subheader>
                <v-subheader>Contacto</v-subheader>

                <v-list-item @click="openLink('Celular', item)">
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-cellphone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Celular || 'Ninguno' }}</v-list-item-title>
                    <v-list-item-subtitle>Celular</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="openLink('Telefono', item)">
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-phone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Telefono || 'Ninguno' }}</v-list-item-title>
                    <v-list-item-subtitle>Fijo</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="openLink('Email', item)">
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Email || 'Ninguno' }}</v-list-item-title>
                    <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="openLink('DomicilioEncoded', item)">
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-map-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Calle }} {{ item.Nro }} {{ item.Piso }} {{ item.Depto }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.Localidad }}, {{ item.Provincia }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeContacto">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCtaCte" scrollable fullscreen>
          <CtaCteList
            :active="dialogCtaCte"
            :cliente="clienteCtaCte"
            @dismiss="closeCtaCte"
          />
        </v-dialog>
      </v-toolbar>

      <v-dialog v-model="dialog" scrollable max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">
              <v-badge
                :color="item.Activo ? 'success' : 'error'"
                dot
              >
                {{ item.RazonSocial }}
              </v-badge>
            </span>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text style="height: 300px;">
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.NroCuenta }}</v-list-item-title>
                  <v-list-item-subtitle>Nro. de Cuenta</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.TipoDoc }} {{ item.NroDoc }}</v-list-item-title>
                  <v-list-item-subtitle>Documento</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.categoriaIva }}</v-list-item-title>
                  <v-list-item-subtitle>Categoría IVA</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.IngresosBrutos }}</v-list-item-title>
                  <v-list-item-subtitle>Ingresos Brutos</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeItem">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogContacto" scrollable max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ item.RazonSocial }}</span>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text style="height: 300px;">
            <v-list dense two-line subheader>
              <v-subheader>Contacto</v-subheader>

              <v-list-item @click="openLink('Celular', item)">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-cellphone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.Celular }}</v-list-item-title>
                  <v-list-item-subtitle>Celular</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openLink('Telefono', item)">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.Telefono }}</v-list-item-title>
                  <v-list-item-subtitle>Fijo</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item @click="openLink('Email', item)">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.Email }}</v-list-item-title>
                  <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item @click="openLink('DomicilioEncoded', item)">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.Calle }} {{ item.Nro }} {{ item.Piso }} {{ item.Depto }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.Localidad }}, {{ item.Provincia }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeContacto">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCtaCte" scrollable fullscreen>
        <CtaCteList
          :active="dialogCtaCte"
          :cliente="clienteCtaCte"
          @dismiss="closeCtaCte"
        />
      </v-dialog>
    </template>
    <template v-slot:item.NroDoc="{ item }" class="text-right">
      {{ item.TipoDoc }} {{ item.NroDoc }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="info"
        class="ml-4"
        @click="openContacto(item)"
        aria-label="Datos de Contacto"
        title="Datos de Contacto"
      >
        mdi-card-account-phone
      </v-icon>
      <v-icon
        color="info"
        class="ml-4"
        @click="openCtaCte(item)"
        aria-label="Cuenta Corriente"
        title="Cuenta Corriente"
      >
        mdi-bank
      </v-icon>
      <v-icon
        color="info"
        class="ml-4"
        @click="openCrearPedido(item)"
        aria-label="Crear Pedido"
        title="Crear Pedido"
      >
        mdi-cart
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>

import CtaCteList from '../components/CtaCteList'

import _ from 'lodash'

export default {
  components: {
    CtaCteList,
  },

  data: () => ({
    loading: false,
    dialogContacto: false,
    dialogCtaCte: false,
    search: '',
    headersXs: [
      { text: 'Razón Social', value: 'RazonSocial', sortable: false },
      { text: 'Acciones', value: 'action', sortable: false, align: 'right' },
    ],
    headers: [
      { text: 'ID', value: 'Id', align: 'left', sortable: false },
      { text: 'Razón Social', value: 'RazonSocial', sortable: false },
      { text: 'Categoría IVA', value: 'categoriaIva', sortable: false },
      { text: 'Documento', value: 'NroDoc', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    filtro: {
      desde: null,
      hasta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 10,
    },
    item: {},
    itemDefault: {
      Email: '',
      Activo: false,
      Depto: '',
      Nro: '',
      categoriaIva: '',
      Localidad: '',
      Provincia: '',
      Piso: '',
      Celular: '',
      TipoDoc: '',
      IngresosBrutos: '',
      Telefono: '',
      RazonSocial: '',
      Calle: '',
      Id: null,
      NroCuenta: null,
      NroDoc: '',
    },
    clienteCtaCte: {},
    zonas: [],
    idZona: null,
  }),

  watch: {
    options: {
      handler () {
        this.query()
        this.queryZonas()
      },
      deep: true,
    },

    search: function () {
      this.debounceRefresh()
    },

    idZona: function () {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filtro.consulta = this.search
      this.filtro.pagina_solicitada = this.options.page
      this.filtro.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarClientes', {
        filtro: this.filtro,
        idZona: this.idZona ? this.idZona.id : null,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = response.data.filtros.totalRegistros
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    openContacto (item) {
      this.loading = true

      this.$soapClient.call('getCliente', {
        id: item.Id,
      })
      .then((response) => {
        this.item = response.data.resultado
        this.dialogContacto = true
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    closeContacto () {
      this.dialogContacto = false
      this.item = {...this.itemDefault}
    },

    openCtaCte (item) {
      this.clienteCtaCte = item
      this.dialogCtaCte = true
    },

    closeCtaCte () {
      this.dialogCtaCte = false
    },

    openLink (key, item) {
      switch (key) {
        case "Celular":
          window.location.href = 'tel:'+item.Celular
          break;
        case "Telefono":
          window.location.href = 'tel:'+item.Telefono
          break;
        case "Email":
          window.location.href = 'mailto:'+item.Email
          break;
        case "DomicilioEncoded":
          window.open('https://www.google.com/maps/search/?api=1&query='+item.DomicilioEncoded, '_blank')
          break;
        default:
          window.alert('Opción no válida')
      }
    },

    queryZonas() {
      this.$soapClient.call('listarZonas', {
      })
      .then((response) => {
        this.zonas = response.data.resultado
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
      })
    },

    openCrearPedido (item) {
      this.$router.push({
        name: 'pedidos/crear/por-articulo',
        query: {
          cliente: item.Id,
        }
      })
    },
  },
};
</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> .cantRegistros_label {
    flex: 1;
  }

  >>> .cantRegistros {
    flex: 1;
    max-width: 120px;
  }
</style>
