<template>
  <v-card>
    <v-card-title class="flex-column flex-sm-row">
      <span>{{ ctaCte.cliente.RazonSocial }}</span>
      <v-spacer></v-spacer>
      <span class="d-flex d-sm-inline-flex justify-center align-center">
        <span class="cantRegistros_label caption d-flex">
          Cant. de Registros
        </span>
        <span class="cantRegistros d-flex d-sm-inline">
          <v-select
            v-model="cantMovimientos"
            :items="itemsPerPage"
            label="Cant. de Registros"
            item-value="value"
            item-text="desc"
            dense
            solo
            flat
            hide-details
            @change="refreshMovimientos"
          ></v-select>
      </span>
      </span>
      <v-spacer></v-spacer>
      <span>Saldo: {{ ctaCte.saldo }}</span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="ctaCte.movimientos"
        :loading="loading"
        class="elevation-1"
        item-key="id"
        :hide-default-header="$vuetify.breakpoint.xsOnly"
        hide-default-footer
      >
        <template v-slot:body.append="{ headers, items }">
          <tr class="d-none d-sm-table-row">
            <td :colspan="headers.length - 1" class="subtitle-2 text-right">
              Saldo Anterior
            </td>
            <td class="text-right">
              {{ items.length ? items[items.length - 1].SaldoAnterior : 0 }}
            </td>
          </tr>

          <tr class="d-table-row d-sm-none v-data-table__mobile-table-row">
            <td class="v-data-table__mobile-row">
              <div class="v-data-table__mobile-row__header">
                Saldo Anterior
              </div>
              <div class="v-data-table__mobile-row__cell">
                {{ items.length ? items[items.length - 1].SaldoAnterior : 0 }}
              </div>
            </td>
          </tr>
        </template>
        <template v-if="$vuetify.breakpoint.xsOnly" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.idTmp" class="v-data-table__mobile-table-row">
              <td class="v-data-table__mobile-row">
                <div class="v-data-table__mobile-row__cell">{{ item['Fecha Formateada'] }}</div>
                <div class="v-data-table__mobile-row__cell">{{ item['Importe'] }}</div>
                <div class="v-data-table__mobile-row__header">{{ item['Saldo'] }}</div>
              </td>
              <td class="v-data-table__mobile-row">
                <div class="v-data-table__mobile-row__cell">
                  {{ item['Comprobante'] }}
                </div>
                <div class="v-data-table__mobile-row__cell text-truncate v-data-table__mobile-row__cell_detalle">
                  {{ item['Detalle'] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        icon
        color="primary"
        @click="download"
        :loading="downloading"
        title="Descargar"
        aria-label="Descargar">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        @click="sendWhatsapp"
        :loading="whatsapping"
        title="Enviar por Whatsapp"
        aria-label="Enviar por Whatsapp">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        @click="sendEmail"
        :loading="emailing"
        title="Enviar por Email"
        aria-label="Enviar por Email">
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="close">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'CtaCteList',

  props: {
    active: Boolean,
    cliente: Object,
  },

  data: () => ({
    ctaCte: {},
    ctaCteDefault: {
      cliente: {},
      movimientos: [],
      saldo: null,
    },
    loading: false,
    downloading: false,
    whatsapping: false,
    emailing: false,
    cantMovimientos: 10,
    itemsPerPage: [
      {value: 10, desc: '10'},
      {value: 20, desc: '20'},
      {value: 50, desc: '50'},
      {value: 100, desc: '100'},
      {value: '', desc: 'Todos'},
    ],
    headers: [
      { text: 'Fecha', value: 'Fecha Formateada', sortable: false },
      { text: 'Comprobante', value: 'Comprobante', sortable: false },
      { text: 'Detalle', value: 'Detalle', sortable: false },
      { text: 'Debe', value: 'Debe', align: 'right', sortable: false },
      { text: 'Haber', value: 'Haber', align: 'right', sortable: false },
      { text: 'Saldo', value: 'Saldo', align: 'right', sortable: false },
    ],
  }),

  watch: {
    active: function (val) {
      if (val) this.query()
    },
  },

  methods: {
    download () {
      this.downloading = true

      this.$soapClient.call('getCtasCtesPdf', {
        inNroCuenta: this.ctaCte.cliente.NroCuenta,
        inCantDias: null,
        inCantMovimientos: this.cantMovimientos,
      })
      .then((response) => {
        window.open(response.data.resultado, '_jqinfo_ctacte');
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.downloading = false
      })
    },

    sendWhatsapp () {
      this.whatsapping = true

      this.$soapClient.call('getCtasCtesPdf', {
        inNroCuenta: this.ctaCte.cliente.NroCuenta,
        inCantDias: null,
        inCantMovimientos: this.cantMovimientos,
      })
      .then((response) => {
        window.open(`https://api.whatsapp.com/send?text=${response.data.resultado}`, '_blank');
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.whatsapping = false
      })
    },

    sendEmail () {
      this.emailing = true

      this.$soapClient.call('getCtasCtesPdf', {
        inNroCuenta: this.ctaCte.cliente.NroCuenta,
        inCantDias: null,
        inCantMovimientos: this.cantMovimientos,
      })
      .then((response) => {
        window.open(`mailto:?body=${response.data.resultado}`, '_blank');
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.emailing = false
      })
    },

    query () {
      this.loading = true

      this.$soapClient.call('getCtasCtes', {
        inNroCuenta: this.cliente.NroCuenta,
        inCantDias: null,
        inCantMovimientos: this.cantMovimientos,
      })
      .then((response) => {
        this.ctaCte = response.data.resultado
        this.ctaCte.cliente = this.cliente
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    refreshMovimientos () {
      this.loading = true

      this.$soapClient.call('getCtasCtes', {
        inNroCuenta: this.ctaCte.cliente.NroCuenta,
        inCantDias: null,
        inCantMovimientos: this.cantMovimientos,
      })
      .then((response) => {
        this.ctaCte.movimientos = response.data.resultado.movimientos
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    close () {
      this.ctaCte = {...this.ctaCteDefault}
      this.$emit('dismiss')
    },
  },

  created () {
    this.ctaCte = {...this.ctaCteDefault}
  },

  mounted () {
    this.query()
  },
};

</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }

  >>> .v-data-table__mobile-row__cell_detalle {
    max-width: 200px;
  }
</style>
